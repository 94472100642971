<template>
    <div>
     <div class="col-lg-12">
            <div class="pagination-counter">
                <ul class="pagination">
                    <li class="page-item" v-for="(link, i) in pagination.links" :key="i">
                        <a class="page-link icons" @click="nextPag(link.url)" :class="link.active?'active ':'disabled'"
                            aria-label="Previous" v-html="link.label">
                            <!-- <i class="fa fa-chevron-left"
                                aria-hidden="true"></i> -->
                        </a>
                        </li>
                    <!-- <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">...</a></li>
                    <li class="page-item"><a class="page-link" href="#">9</a></li>
                    <li class="page-item"><a class="page-link" href="#">10</a></li>
                    <li class="page-item"><a class="page-link icons" href="#"
                            aria-label="Next"><i class="fa fa-chevron-right"
                                aria-hidden="true"></i></a></li> -->
                </ul>
            </div>
            <p class="pagination-result-counter"><strong>{{pagination.count}} of {{pagination.total}}</strong> showing based on {{title}}</p>
        </div>
    </div>
</template>
<script>
export default {
    name:"Pagination",
    props:{
        pagination:{type:Object, required:true},
        title:{type:String},
        mutator:{type:String},
        method:{type:String,default:'get'}
    },
    data(){
        return{
            totalPage:'',

        }
    },methods:{
        nextPag(url){
            let link =  process.env.VUE_APP_URL;
            // let path = this.pagination.path;
            let u = url.replace(link,'');
            let form =null;
            if(this.method=='post'){
                form = {
                    price : this.$store.state.property_module.form.max_loan_amount,
                    location:this.$store.state.property_module.form.location
                }
            }
            let data = {url :u, mutator:this.mutator,method:this.method,form:form}
            this.$store.dispatch("paginationAction",data);
        }
    }
}
</script>