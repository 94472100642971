<template>
    <div>
    <div class="modal fade" :ref="name" tabindex="-1" aria-hidden="true" data-bs-backdrop="static"  >
    <div class="modal-dialog"  :style="{width:width}">
      <div class="modal-content">
        <div class="modal-header">
                 
          <!-- <h5 class="modal-title text-center" id="exampleModalLabel">Modal title</h5> -->
           <!-- data-bs-backdrop="static" -->
            <!-- <app-icon img="close_outline.png" size="30px" class="btn-close" @click="closeModal()" aria-label="Close"/> -->
            
          <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="top-modal-title">
                 <slot  name="title"></slot>
            </div>
          
           <slot name="content"></slot>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modal.hide()">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
      </div>
    </div>
  </div>
    </div>
</template>
<script>
import EventBus from '@/services/event'
import { Modal } from 'bootstrap'
export default {
    name:"Modal",
    props:{
        width:{type:String,default:"500px"},
        name:{type:String}
    },
    data(){
        return{
            modal:null
        }
    },
    methods:{
        closeModal(){
              this.modal.hide();
        }
    },
    created(){
        EventBus.$on('show-modal',(t)=>{
            this.modal.show();
            console.log('show from afford')
        });
        EventBus.$on('hide-modal',(t)=>{
            this.modal.hide();
        });
    },
    mounted(){
          this.modal = new Modal(this.$refs[this.name])
    }
}
</script>
<style scoped>
    .btn-close{
        cursor: pointer;
        width:30px;
        height: 30px;
    }
    .modal-header{
        border:0;
    }
    .top-modal-title{
        margin-top:-3em;
        margin-bottom:1em;
    }
  
</style>